<template>
  <el-select
    placeholder="请选择角色"
    v-model="roles"
    :multiple="multiple"
    style="width:100%"
    @change="change"
    :disabled="isDisabledRoles"
  >
    <el-option
      v-for="(item, index) in rolesList"
      :key="index"
      :value="item.id"
      :label="item.name"
    ></el-option>
  </el-select>
</template>
<script>
import deptMixins from "../mixins";
export default {
  name: "RolesVue",
  mixins: [deptMixins],
  props: {
    role_id: {},
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      roles: "",
      rolesList: []
    };
  },
  computed: {},
  mounted() {
    this.getRoles();
  },
  methods: {
    change() {
      this.$emit("setData", this.roles);
    }
  }
};
</script>
